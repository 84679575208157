// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './Reducers/Reducer'; // Combine all your reducers in rootReducer
import rootSaga from './sagas/Saga'; // Combine all your sagas in rootSaga

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false, // Disable thunk if you're using only saga
      serializableCheck: false, // Disable serializableCheck if you are working with non-serializable values
    }).concat(sagaMiddleware),
  devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools in development
});

// Run the root saga
sagaMiddleware.run(rootSaga);

export default store;
