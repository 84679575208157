import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UserData from '../utils/UserData';
import { useIsAuthenticated } from '@azure/msal-react';
import PrefixPath from 'config/AppConfig';

function PublicRoute({ element }) {
  const {getUserLoginToken} = UserData()
  let isToken = useIsAuthenticated();
  const isAuthenticated = getUserLoginToken();
  return isToken && isAuthenticated ? <Navigate to={`${PrefixPath.APP_PREFIX_PATH}/dashboard`} /> : element;
}

export default PublicRoute;
