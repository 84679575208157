import React from 'react';
import { Radio } from 'antd';
 
const RadioButton = (props) => {
  const { options, name, label, className, onChange, value } = props;
 
  const handleChange = (e) => {
   
    const { value } = e.target;
     const selectedOption = options.find(option => option.value === value);
     const selectedName = selectedOption ? selectedOption.name : '';
    const obj = {
      target: {
        id: name,
        value: value,
      },
    };
    onChange(obj,selectedName);
  };
 
 
  return (
    <>
    <div className='radio-box-wrap'>
    {label && <label className={className}>{label}</label>}
    <Radio.Group
      name={name}
      onChange={handleChange}
      value={value} // Ensure the value prop is used to control the selected radio button
    >
      {options && Array.isArray(options) && options.length > 0 &&
        options.map((data, index) => (
          <Radio disabled={data?.default} key={index} className={className} value={data.value}>
            {data.name}
          </Radio>
        ))
      }
    </Radio.Group>
    </div>
    </>
  );
};
 
export default RadioButton;