
export const ROLES = {
    ADMIN: 'Admin',
    BOARD_ADMIN: 'Board Admin',
    USER: 'User',
  };
  
  export const ROLE_HIERARCHY = {
    [ROLES.ADMIN]: ['Admin', 'Board Admin', 'User'],
    [ROLES.BOARD_ADMIN]: ['Board Admin'],
    [ROLES.USER]: ['User'],
  };
  