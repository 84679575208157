import { PublicClientApplication,LogLevel } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: "490a2707-a708-440f-b173-9b8e42644e0e",
    authority: `https://login.microsoftonline.com/714b12a9-398a-4d36-98ff-d36c67bd1e93`, 
    redirectUri: '/', 
    postLogoutRedirectUri: '/logout',
    navigateToLoginRequestUrl: false,
  },
  cache: {
      cacheLocation: 'localStorage', 
      storeAuthStateInCookie: true, 
  },
  system: {
      loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
              if (containsPii) {
                  return;
              }
              switch (level) {
                  case LogLevel.Error:
                      console.error(message);
                      return;
                  case LogLevel.Info:
                      console.info(message);
                      return;
                  case LogLevel.Verbose:
                      console.debug(message);
                      return;
                  case LogLevel.Warning:
                      console.warn(message);
                      return;
                  default:
                      return;
              }
          },
      },
  },
};
class LoginRequest{
  static loginRequest = {
    scopes: [],
    prompt: "select_account"
};
static silentRequest = {
  scopes: ["openid", "profile"],
  loginHint: "example@domain.net"
};
}

export const msalInstance = new PublicClientApplication(msalConfig);
export default LoginRequest;
