import Button from './Button/Button';
import CheckBox from './CheckBox/CheckBox';
import DateTime from './dateTime/DateTime';
import InputNumber from './InputNumber/InputNumber';
import Radio from './Radio/RadioButton';
import RadioCircle from './Radio/Radio';
import SelectBox from './SelectBox/SelectBox';
import Spinner from './Spinner/Spiner';
import TextInput from './TextInput/TextInput';
import Typography from './Typography/Typography';
import UploadButton from './UploadButton/UploadButton';
import Modal from './Modal/Modal';
import SideBar from './sideBar/SideBar';
import Loading from './DropDownSpinner/DropDownSpinner';
import HeaderNav from "./layout-components/Sidebar";
import DynamicTable from "./Table/Table";
import ProfileInfo from './ProfileInfo/ProfileInfo';
import CheckboxTable from './Table/AntdTable'
import Caption from './Table/TableCaption';
import MultiInput from './TextInput/MultiInput';
import DragTable from './Table/DraggbleTable';
import Layout from './Layout/AuthLoyout';
import MainLayout from './Layout/Layouts';
import Tabs from './Tabs/Tabs';
import DropdownComponent from './DropDown/DropDown';
class Component{
    static Button = Button;
    static CheckBox = CheckBox;
    static DateTime = DateTime;
    static InputNumber = InputNumber;
    static Radio = Radio;
    static SelectBox = SelectBox;
    static TextInput = TextInput;
    static Spinner = Spinner;
    static Typography = Typography;
    static UploadButton = UploadButton;
    static Modal = Modal;
    static SideBar = SideBar;
    static MiniLoader = Loading;
    //static MenuBar = MenuBar;
    static DynamicTable = DynamicTable;
    static ProfileInfo = ProfileInfo;
    static CheckboxTable = CheckboxTable;
    static Caption = Caption;
    static MultiInput = MultiInput;
    static RadioCircle = RadioCircle;
    static DragTable = DragTable;
    static Tabs =Tabs;
    static DropdownComponent = DropdownComponent;

}
export class UtilsComponent{
    static HeaderNav = HeaderNav;
    static Layout = Layout;
    static MainLayout = MainLayout;
}
export default Component;