import { Button, Col, Row } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import TextInput from "../TextInput/TextInput";

const Caption = ({ isButton, handleToggle, title, actionName, path, searchTerm, handleSearch }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="d-flex justify-content-between page-header-wrap w-100">
        <h1 className="primary-heading">{title}</h1>
        <div className="d-flex align-items-center gap-3">
          <Col>
            <TextInput
              className="search-bar-wrapper"
              value={searchTerm}
              onChange={handleSearch}
              icon={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 14L11.1 11.1M12.6667 7.33333C12.6667 10.2789 10.2789 12.6667 7.33333 12.6667C4.38781 12.6667 2 10.2789 2 7.33333C2 4.38781 4.38781 2 7.33333 2C10.2789 2 12.6667 4.38781 12.6667 7.33333Z" stroke="#757575" stroke-linecap="round" stroke-linejoin="round" />
              </svg>}
              placeholder={"Search here"}
            />
          </Col>
          {isButton && <Col className="text-center">
            <Button
            className="filled-button"
              onClick={() => {
                handleToggle();
                navigate(path);
              }}
            >
              {actionName}
            </Button>
          </Col>}
        </div>
      </div>
    </>
  );
};
export default Caption;