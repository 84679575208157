import { takeLatest, call, put } from 'redux-saga/effects';
import Constant from '../../Constants/Constants';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import Auth, { loginSuccess, loginFailure, loginRequest, Sidebar } from '../../Actions/Auth/Auth';
import JwtAuthService from '../../../services/JwtAuthService';
import Service from '../Services/Service';
import { removeCookie, setCookie } from '../../../utils/AllCookies';


function* logoutSaga(action) {
  removeCookie(); // Clear all cookies
  const { instance } = action.payload;

  // Clear session storage and local storage
  sessionStorage.clear();
  localStorage.clear(); // Clears all local storage items

  // Trigger instance logout
  yield call([instance, instance.logout]);

  // Optionally, you can also dispatch an action to update the application state if needed
  // yield put({ type: 'LOGOUT_SUCCESS' });
}

// function* getDetailsSaga(action) {
//     try {
//       const { instance, activeAccount } = action.payload;
//       if (!activeAccount) throw new Error("Active account is undefined");
//       const accessTokenRequest = {
//         scopes: ['user.read'],
//         account: activeAccount,
//       };
//       console.log(activeAccount,"accessTokenResponseaccessTokenResponseaccessTokenResponse",instance.acquireTokenSilent,instance)
//       const accessTokenResponse = yield call([instance, instance.acquireTokenSilent], accessTokenRequest);
//       console.log(accessTokenResponse,"accessTokenResponseaccessTokenResponseaccessTokenResponse")
//       const accessToken = accessTokenResponse.accessToken;
//       const user = yield call(Service.getUserDetails, accessToken);
//       const userGroups = yield call(Service.getUsersGroups, accessToken);
  
//       const usersGroups = userGroups.value.map(group => group.id);
  
//       let username = user.mail || user.userPrincipalName;
  
//       const data = {
//         Username: username,
//         Password: 'Azure Authenticated',
//         FirstName: user.givenName,
//         LastName: user.surname,
//         UserGroups: usersGroups,
//         ADUniqueID: user.id,
//       };
//        yield put( loginRequest(data));
//     //   loginRequest(data)
//       yield put(Auth.getDetailsSuccess(data));
  
//     } catch (error) {
//       if (error instanceof InteractionRequiredAuthError) {
//         const { instance, accessTokenRequest } = action.payload;
//         yield call([instance, instance.acquireTokenRedirect], accessTokenRequest);
//       } else {
//         yield put(Auth.getDetailsFailure(error.message));
//       }
//     }
//   }
  
function* getDetailsSaga(action) {
  try {
    const { instance, activeAccount } = action.payload;

    const accessTokenRequest = {
      scopes: ['user.read'],
      account: activeAccount,
    };

    let accessTokenResponse;

    // Attempt to acquire token silently
    try {
      console.log("Trying acquireTokenSilent with", accessTokenRequest);
      accessTokenResponse = yield call([instance, instance.acquireTokenSilent], accessTokenRequest);
      console.log("Trying acquireTokenSilent with", accessTokenRequest);
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        console.warn("Silent token acquisition failed; interaction required. Redirecting...");
        yield call([instance, instance.acquireTokenRedirect], accessTokenRequest);
        return; // Exit the saga, as the redirect will refresh the page.
      } else {
        console.error("Unexpected error in acquireTokenSilent:", error);
        yield put(Auth.getDetailsFailure(error.message));
        return;
      }
    }

    // Process if token response is successful
    const accessToken = accessTokenResponse.accessToken;
    const user = yield call(Service.getUserDetails, accessToken);
    const userGroups = yield call(Service.getUsersGroups, accessToken);

    const usersGroups = userGroups.value.map(group => group.id);
    const username = user.mail || user.userPrincipalName;

    const data = {
      Username: username,
      Password: 'Azure Authenticated',
      FirstName: user.givenName,
      LastName: user.surname,
      UserGroups: usersGroups,
      ADUniqueID: user.id,
    };
    if(usersGroups?.length > 0){
      yield put( loginRequest(data));
    //     //   loginRequest(data)
    yield put(Auth.getDetailsSuccess(data));
    }

  } catch (error) {
    console.error("Error in getDetailsSaga:", error);
    yield put(Auth.getDetailsFailure(error.message));
  }
}

  function* hfhLogin(data) {
    try {
        const response = yield call(JwtAuthService.postApi,data?.payload,"api/account/login");
        setCookie(Constant.AUTH_TOKEN, response, 8);
        yield put(loginSuccess(response));
    } catch (error) {
        yield put(loginFailure(error.message));
    }
    
}

// function* handleToggleCollapse() {
//   yield put(Sidebar());
// }

export function* watchAuth() {
  yield takeLatest(Constant.LOGIN_REQUEST, hfhLogin);
  yield takeLatest(Constant.LOGOUT, logoutSaga);
  yield takeLatest(Constant.GET_DETAILS_REQUEST, getDetailsSaga);
  //yield takeLatest(Constant.IS_SIDEBAR, handleToggleCollapse);
}
