import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { msalInstance } from "./config";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
const disable = () => {
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
};

//disable();
root.render(
  <MsalProvider instance={msalInstance}>
     <Provider store={store}>
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </Provider>
</MsalProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
