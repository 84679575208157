import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Image, Upload, message } from "antd";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
//import UserAction from "../../redux/actions/UsersActions/UserActions";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const UploadButton = () => {
  const dispatch = useDispatch();
  const User = useSelector(state => state?.GetUser?.GetUser)
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const handleUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("Name", file?.name);
    formData.append("dir", null);
    formData.append("UserId",User?.data?.userId)
    try {
      const req = {
        request:formData,
        handle: handleFile
      }
     // dispatch(UserAction.UploadFileRequest(req))
      message.success(`${file.name} uploaded successfully.`);
    } catch (error) {
      console.error("Upload error:", error);
      message.error(`${file.name} upload failed.`);
    }
  };
const handleFile = ()=>{}
  const customRequest = ({ file, onSuccess }) => {
    handleUpload(file).then(() => onSuccess("ok"));
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  return (
    <>
      <Upload
        customRequest={customRequest}
        listType="picture-circle"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        multiple={true}
      >
        {fileList.length >= 8 ? null : uploadButton}
      </Upload>
      {previewImage && (
        <Image
          wrapperStyle={{
            display: "none",
          }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(""),
          }}
          src={previewImage}
        />
      )}
    </>
  );
};

export default UploadButton;
