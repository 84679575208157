import React, { memo } from 'react';
import { Form, Select,Tag  } from 'antd';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
const { Option } = Select;

const tagRender = (props) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color="#5c7a93"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginInlineEnd: 4,
      }}
    >
      {label}
    </Tag>
  );
};
const SelectBox = (props) => {
  const {handleDropdownVisibleChange,disabled ,filterOption,Value,showSearch,maxCount,onChange,onSearch,isLoading,size,name,label,rules,mode,placeholder,options,className} = props;
  const handleValue = (value)=>{
    const obj = {
    target:{
      id:name,
      value:value
    }
    }
onChange(obj)
  }

  return (
    <>
      <Form.Item name={name}  value={Value} label={label} rules={rules} >
      <Select disabled={disabled}  suffixIcon={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 9L12 15L18 9" stroke="#606060" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>} onDropdownVisibleChange={handleDropdownVisibleChange} onChange={(e)=>handleValue(e)} showSearch={showSearch} maxCount={maxCount} className={`${className} select-box`} tagRender={tagRender} mode={mode} size={size} placeholder={placeholder}  onSearch={onSearch}
    filterOption={filterOption} notFoundContent={isLoading}>
        {options &&
          Array.isArray(options) &&
          options.length > 0 &&
          options.map((option, index) => (
            <Option key={index} value={option.value}>
              {option.name}
            </Option>
          ))}
      </Select>
    </Form.Item>
    </>
  );
};

export default memo(SelectBox);
