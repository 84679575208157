import { ROLE_HIERARCHY } from "../Constant/Roles";

class Utils{
    static userHasRequiredRole = (userRoles, requiredRoles) => {
        return requiredRoles?.some(requiredRole => 
          userRoles.some(userRole => ROLE_HIERARCHY[userRole]?.includes(requiredRole))
        );
      };

      static UserListList = (userList, id) => {
        const filterList =
        userList?.length > 0 &&
        userList?.filter((data) => data?.value === id);
        if (filterList && filterList?.length > 0) {
          return filterList;
        } else {
          return userList;
        }
      };
}

export default Utils;