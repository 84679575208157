import { takeLatest, call, put } from 'redux-saga/effects';
import Constant from '../../Constants/Constants';
import {Community} from '../../Actions';
import JwtAuthService from '../../../services/JwtAuthService';


  function* GetAllCommunitysList(data) {
    try {
        const response = yield call(JwtAuthService.getAPI,'',`api/Users/getAllCommunitys`);
        yield put(Community.GetAllCommunitysSuccess(response));
    } catch (error) {
        yield put(Community.GetAllCommunitysFailure(error.message));
    }
    
}

function* GetCommunityById(data) {
    try {
        const response = yield call(JwtAuthService.getAPI,'',`api/Users/getCommunityById?userId=${data?.payload?.request?.userId}&communityId=${data?.payload?.request?.communityId}`);
         response.community = data;
        yield put(Community.GetCommunityByIdSuccess(response));
    } catch (error) {
        yield put(Community.GetCommunityByIdFailure(error.message));
    }
    
}

function* EditCommunityById(data) {
    try {
      

        const response = yield call(JwtAuthService.updateApi,data?.payload?.request,`api/Communitys/updateCommunityById`);
        response.community = data?.payload?.request;
        yield put(Community.EditCommunityByIdSuccess(response));
        data?.payload.handleRes(response);
       
    } catch (error) {
        yield put(Community.EditCommunityByIdFailure(error.message));
    }
    
}

function* StatusUpdateCommunityById(data) {
    try {
        const response = yield call(JwtAuthService.updateApi,'',`api/Communitys/communityStatus?Status=${data?.payload?.request?.status}&Id=${data?.payload?.request?.Id}`);
        yield put(Community.StatusUpdateCommunityByIdSuccess(response));
       
    } catch (error) {
        yield put(Community.StatusUpdateCommunityByIdFailure(error.message));
    }
    
}

function* PermissionUpdateCommunityById(data) {
    try {
        const response = yield call(JwtAuthService.postApi,'',`api/Users/setBoardViewPermission?communityId=${data?.payload?.request?.communityId}&userId=${data?.payload?.request?.userId}&permissionId=${data?.payload?.request?.permissionId}`);
        yield put(Community.PermissionUpdateCommunityByIdSuccess(response));
        if(response?.status){
            data?.payload?.handle();
        }
       
    } catch (error) {
        yield put(Community.PermissionUpdateCommunityByIdFailure(error.message));
    }
    
}
export function* Communitys() {
  yield takeLatest(Constant.GET_ALL_COMMUNITYS_REQUEST, GetAllCommunitysList);
  yield takeLatest(Constant.GET_COMMUNITY_BYID_REQUEST, GetCommunityById);
  yield takeLatest(Constant.UPDATED_COMMUNITY_BYID_REQUEST, EditCommunityById);
  yield takeLatest(Constant.STATUS_UPDATE_COMMUNITY_BYID_REQUEST,StatusUpdateCommunityById);
  yield takeLatest(Constant.PERMISSION_UPDATE_COMMUNITY_BYID_REQUEST,PermissionUpdateCommunityById)
}
