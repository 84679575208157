// src/components/Layout.js
import React from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import Logo from 'Components/layout-components/Logo';
// import Sidebar from './Sidebar';

const { Content } = Layout;

const MainLayout = () => (
  <Layout style={{ minHeight: '100vh', background: '#fff' }}>
    {/* <Sidebar /> */}
    <Layout style={{ background: '#fff',paddingLeft:"20px",paddingTop:"20px" }}>
      <Logo />
      <Content style={{ margin: '24px 16px 0' }}>
        <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
          <Outlet />
        </div>
      </Content>
    </Layout>
  </Layout>
);

export default MainLayout;
