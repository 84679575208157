// src/components/MainLayout.js
import React from 'react';
import { Col, Row, Typography } from 'antd';
import { useLocation } from 'react-router-dom';
import DynamicBreadcrumb from './BreadCrumb';
import pathToLabelMap from './pathToLabelMap';

const { Title } = Typography;

const MainLayout = ({ children }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const getPageLabel = (path) => {
    for (let route in pathToLabelMap) {
      // Replace :id with a regular expression to match any number (or use [^/]+ for any segment)
      const routeRegex = new RegExp(`^${route.replace(':id', '[^/]+')}$`);
      if (routeRegex.test(path)) {
        return pathToLabelMap[route];
      }
    }
    return 'Page'; // Default label if no match
  };
  
  const pageName = getPageLabel(currentPath);

  return (
    <div className='main-layout' >
      <Row justify={'space-between'} align={'stretch'}>
        <Col sm={20}>
        <Title level={2} className='text'>{pageName}</Title>
        </Col>
        <Col sm={4}>
        <DynamicBreadcrumb />
        </Col>
      </Row>
      {children}
    </div>
  );
};

export default MainLayout;
