import React, { useState, useEffect } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Radio, Switch } from 'antd';
import PropTypes from 'prop-types';

const MultiInput = ({ fieldNames, name, onChange, isEditMode, showRadio, onStatusChange, initialValues }) => {
    const [selectedRadio, setSelectedRadio] = useState(0);
  
    const handleRadioChange = (index) => {
      setSelectedRadio(index);
      onChange(index);
    };
  
    useEffect(() => {
      if (initialValues && initialValues.length > 0) {
        const defaultIndex = initialValues.findIndex(item => item.defaultValue === true);
        setSelectedRadio(defaultIndex >= 0 ? defaultIndex : 0);
      }
    }, [initialValues]);
  
    return (
      <Form.List
        name={name}
        initialValue={initialValues}
        rules={[
          {
            validator: async (_, items) => {
              if (!items || items.length < 1) {
                return Promise.reject(new Error('At least one Device is required'));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            <Form.Item>
              <Button
                type="primary"
                onClick={() => add({ defaultValue : false, status: false })}
                icon={<PlusOutlined />}
              >
                Add Fields
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
            {fields.map((field, index) => (
              <div key={field.key} style={{ display: 'flex', alignItems: 'center' }}>
                {fieldNames.map((fieldName) => (
                  <Form.Item
                    label={index === 0 ? fieldName.label : ''}
                    required={false}
                    key={fieldName.name}
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, fieldName.name]}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: `Please input ${fieldName.label}`,
                        },
                      ]}
                      noStyle
                    >
                      <Input placeholder={fieldName.label} />
                    </Form.Item>
                  </Form.Item>
                ))}
                {showRadio && (
                  <Form.Item label={index === 0 ? 'Default' : ''} required={false}>
                    <Radio
                      checked={selectedRadio === index}
                      onChange={() => handleRadioChange(index)}
                    />
                  </Form.Item>
                )}
                {isEditMode && (
                  <Form.Item label={index === 0 ? 'Status' : ''} required={false}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'status']}
                      valuePropName="checked"
                      noStyle
                    >
                      <Switch
                        onChange={(checked) => onStatusChange(index, checked)}
                        checkedChildren="Active"
                        unCheckedChildren="Inactive"
                      />
                    </Form.Item>
                  </Form.Item>
                )}
                {fields.length > 1 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                    style={{ margin: '0 8px' }}
                  />
                ) : null}
              </div>
            ))}
          </>
        )}
      </Form.List>
    );
  };
  
  MultiInput.propTypes = {
    fieldNames: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    showRadio: PropTypes.bool,
    onStatusChange: PropTypes.func.isRequired,
    initialValues: PropTypes.array,
  };
  
  MultiInput.defaultProps = {
    isEditMode: false,
    showRadio: true,
    initialValues: [{ defaultValue: false, status: false }],
  };
  
  export default MultiInput;
