import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import UserData from '../utils/UserData';
import { useIsAuthenticated } from '@azure/msal-react';
function PrivateRoute({ element, requiredRoles }) {
  const navigate = useNavigate();
  const {getUserLoginToken} = UserData()
  const isAuthenticated = getUserLoginToken();
  const istoken = useIsAuthenticated() ;
  if (!isAuthenticated || !istoken) {
    return navigate('/login');
  }

  if (requiredRoles && !requiredRoles.includes("Admin")) {
    return navigate("/unauthorized");
  }

  return element;
}

export default PrivateRoute;
