import React from 'react';
import { DownOutlined, SettingOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';

const DropdownComponent = ({title,items}) => (
  <Dropdown
    menu={{
        items,
    }}
  >
    <a style={{color:"rgb(92, 122, 147)"}} onClick={(e) => e.preventDefault()}>
      <Space>
        {title}
        <DownOutlined />
      </Space>
    </a>
  </Dropdown>
);
export default DropdownComponent;