import React from 'react';
import { Table } from 'antd';

const calculateColumnWidth = (text, padding = 16) => {
  return text?.length * 8 + padding;
};

const generateColumnsWithWidth = (columns, data) => {
  return columns.map((column) => {
    const longestValue = data?.reduce((max, row) => {
      const value = row[column.dataIndex];
      return value && value?.length > max?.length ? value : max;
    }, column.title);

    const width = calculateColumnWidth(longestValue);
    return { ...column, width, className: 'custom-column-class' }; 
  });
};

const DragTable = ({ onRow,components,columns, data,pagination,onChange }) => {
  const columnsWithWidth = generateColumnsWithWidth(columns, data);

  return (
    <Table 
      columns={columnsWithWidth}
      dataSource={data}
      pagination={pagination}
      rowKey="id"
      rowClassName={'table-header'}
      components={components}
      onRow={onRow}
      scroll={{ x: 400 }}
      onChange={onChange}
    />
  );
};

export default DragTable;
