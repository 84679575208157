
import { all } from 'redux-saga/effects';
import { watchAuth } from '../sagas/Auth/Auth';
import { User,Communitys } from './index';


export default function* rootSaga() {
  yield all([
    watchAuth(),
    User(),
    Communitys(),
  ]);
}
