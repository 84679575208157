import React, { useState } from 'react';
import { Radio,Form } from 'antd';
const RadioButton = (props) => {
    const {options,name,label,rules,className,onChange} = props;
    const handleChane = (e)=>{
      const {value,name} = e?.target;
      let obj = {
        target :{
          id : name,
          value:value
        }
      }
      onChange(obj)
    }
  return (
    <>
    <Form.Item
    name={name}
    label={label}
    rules={rules}
  >
    <Radio.Group name={name} onChange={handleChane}>
   { options && Array.isArray(options) && options?.length > 0 &&  options?.map((data,index)=>(
        <Radio.Button className={className} value={data?.value}>{data?.name}</Radio.Button>
     ))}
    </Radio.Group>
  </Form.Item>
  </>
  );
};
export default RadioButton;