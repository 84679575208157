import { useDispatch, useSelector } from "react-redux";
import { Users,Community } from "../../../redux/Actions";
import { memo, useEffect, useMemo, useState } from "react";
import Component from "Components";
import { EditOutlined ,EyeOutlined,RedoOutlined,ReloadOutlined,SyncOutlined} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { Badge, Button, Card, Col, Empty, Form, List, Popover, Row, Switch, Table, Tooltip, Typography } from "antd";
import CreateUser from "./CreateUser";
import Swal from "sweetalert2";
import Spinner from "Components/Spinner/Spiner";
import CommonFunctions from "utils/CommonFunction/CommonFunctions";
import { CommunityForm } from "../Communitys";
import Messages from "Components/Message/Messages";
const ViewUsers = () => {
  const { DynamicTable, Modal,RadioCircle,SelectBox } = Component;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userid = useParams();
  const [form] = Form.useForm();
  const { loading,password,CommunityDetails, error,usersDetails,genrateLoading, BoardFeatures, Features } =
    useSelector((state) => state?.GetAllUser);
    const {getAllCommunitys,getCommunityById,updateData    } = useSelector((state) => state?.Communitys);
  const [userDirectoryList, setUserDirectoryList] = useState([]);
  const [featureList, setFeatureList] = useState([]);
  const [additionalFeature, setAdditionalFeature] = useState([]);
  const [viewCommunityForm,setViewCommunityForm] = useState(false);
  const [communityData,setCommunityData] = useState({});
  const [featureOptions,setFeatureOptions] = useState([]);
  const [userpermissionId,setUserPermissionId] = useState({})
  const [edit , setEdit] = useState(true);
  const [directoryPagination, setDirectoryPagination] = useState({
    current: 1,
    pageSize: 10,
    filter: "",
    total: 0,
  });
  const [communityPagination, setCommunityPagination] = useState({
    current: 1,
    pageSize: 10,
    filter: "",
    total: 0,
  });
  const [featurePagination, setFeaturePagination] = useState({
    current: 1,
    pageSize: 10,
    filter: "",
    total: 0,
  });
  const [communityList, setCommunityList] = useState([]);
  const [featuresList , setFeaturesList] = useState([]);
  const [userId, setUserId] = useState();
  const [type, setType] = useState(false);
  const [permissionType, setPermissionType] = useState({});
  const communityColumns = [
    
    { title: "Community Name", dataIndex: "communityName",key: 'communityName' },
    { title: "Address", dataIndex: "address",key: 'address' },
    { title: "Phone", dataIndex: "phone",key: 'phone' },
    { title: "Resident Key", dataIndex: "residentKey",key: 'residentKey' },
    { title: "Resident Contact", dataIndex: "residentContactKey",key: 'residentContactKey' },
    { title: "Status", dataIndex: "isActive",render:(value)=> value ? "Active" : "inActive",key: 'isActive' },
    { title: "Modified", dataIndex: "modified",render:(value)=>CommonFunctions.formatDate(value),key: 'modified' },
        { title: "Permissions", dataIndex: "permission", render: (status, record) =>renderPermissionActions(record),key: 'permission' },
    { title: "Actions", dataIndex: "actions", render: (status, record) =>renderActions(record),key: 'actions' },

  ];
  
const HandleCommunityDelete = (data)=>{
  
  if (CommunityDetails?.data?.communityUsers?.length > 0) {
    const filterData = CommunityDetails?.data?.communityUsers?.filter(allData => allData?.id !== data?.id)
    const filter = {
      communityUsers:filterData,
      communtiyList:CommunityDetails?.data?.communtiyList,
      userDirectory:CommunityDetails?.data?.userDirectory
    }
    const NewFilter = {
      data:filter
    }
    let Request = {
      request:{
        status:false,
        Id:data?.id
      }
    }
    dispatch(Users.filterCommunityCommunityByUserIdSuccess(NewFilter))
    dispatch(Community.StatusUpdateCommunityByIdRequest(Request))
    // setCommunityPagination({
    //   ...communityPagination,
    //   total: CommunityDetails?.data?.communityUserTotalRecords,
    // });
  }

}
const Permissions = [
  {
    name: "All Acceess Web User",
    value: 0,
  },
  {
    name: "Finance",
    value: 2,
  },
  {
    name: "Board Member",
    value: 1,
  },
  {
    name: "Architectural",
    value: 3,
  },
];
  const renderActions = (record)=>{
    return(
      <Row >
        <Col >
        <Button onClick={()=>{viewCommunityFormHandle(record)}} type="none" icon={<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 4.49998H4C3.46957 4.49998 2.96086 4.7107 2.58579 5.08577C2.21071 5.46084 2 5.96955 2 6.49998V20.5C2 21.0304 2.21071 21.5391 2.58579 21.9142C2.96086 22.2893 3.46957 22.5 4 22.5H18C18.5304 22.5 19.0391 22.2893 19.4142 21.9142C19.7893 21.5391 20 21.0304 20 20.5V13.5M18.5 2.99998C18.8978 2.60216 19.4374 2.37866 20 2.37866C20.5626 2.37866 21.1022 2.60216 21.5 2.99998C21.8978 3.39781 22.1213 3.93737 22.1213 4.49998C22.1213 5.06259 21.8978 5.60216 21.5 5.99998L12 15.5L8 16.5L9 12.5L18.5 2.99998Z" stroke="#6D6D6D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      }> </Button>
        </Col>
        <Col >
      <Button onClick={()=>{navigate(`/AAM/community/permissions/${record?.communityId}`)}} type="none" icon={<EyeOutlined className="eye"/>}> </Button>
      </Col>
      <Col > <Button  onClick={()=>{HandleCommunityDelete(record)}} type="none" icon={<svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 5.5H3M3 5.5H19M3 5.5V19.5C3 20.0304 3.21071 20.5391 3.58579 20.9142C3.96086 21.2893 4.46957 21.5 5 21.5H15C15.5304 21.5 16.0391 21.2893 16.4142 20.9142C16.7893 20.5391 17 20.0304 17 19.5V5.5M6 5.5V3.5C6 2.96957 6.21071 2.46086 6.58579 2.08579C6.96086 1.71071 7.46957 1.5 8 1.5H12C12.5304 1.5 13.0391 1.71071 13.4142 2.08579C13.7893 2.46086 14 2.96957 14 3.5V5.5M8 10.5V16.5M12 10.5V16.5" stroke="#FF4E4E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
}> </Button> </Col>

           
 
      </Row>
    )
  }
  const renderPermissionActions = (record)=>{
    return(
      <Row >
<Col >
              <Form form={form} initialValues={{["permissionId"+record?.communityId]:record?.permissionsId}}>
                <SelectBox
                  options={Permissions ?? []}
                  className="user-form-input"
                  name={`permissionId${record?.communityId}`}
                  placeholder="Permission"
                //  label="Permission"
                  size="md"
                 
                  //rules={validationRules.permissionId}
                  hasFeedback={true}
                  validateTrigger={"onChange"}
                  onChange={(e)=>{HandleOnChanges(e,record?.communityId)}}
                />
                </Form>
             </Col>
           
 
      </Row>
    )
  }
  const handleBoardTypeChange = (e,selected) => {
    const {value,id} = e.target;
    let select = {
        type:selected,
        value:value,
        name:id
    }

   if(value == 0){
    Swal.fire({
      title: `do you want to remove user permission and apply group permissions for this user`,
      showDenyButton: true,
      confirmButtonText: "Yes",
      denyButtonText: "No",
      confirmButtonColor: "rgb(92, 122, 147)", 
denyButtonColor: "rgb(112 111 111)",        
allowOutsideClick: false    
    }).then((result) => {
      if (result.isConfirmed) {
        HandleCommitee()

      } else if (result.isDismissed) {
       
      }
    });
   }
    setType(value);
  };
  const handleUserStatusChange = (checked)=>{
    const req = {
      userId:userid?.id,
      status:checked
    }
    userpermissionId["permissionId"] = checked;
    setUserPermissionId(userpermissionId)
    dispatch(Users.UserStatusRequest({request:req}))
  }
  const handleSwitchChange = async (id, checked) => {
    try {
      setFeatureList((prevData) =>
        prevData.map((item) =>
          item?.featureId === id?.featureId
            ? { ...item, hasUserPermission : checked }
            : item
        )
      );
      // message.success('Status updated successfully.');
    } catch (error) {
      console.error("Error updating status:", error);
      //message.error('Failed to update status.');
    }
  };
  useEffect(()=>{
    if(updateData?.data){
      const community = getAllCommunitys?.data?.length > 0 && getAllCommunitys?.data?.find(data => data?.value === updateData?.community?.CommunityId )
      let update = CommunityDetails?.data?.communityUsers?.map(user => {
      
        if (user.id === updateData?.community?.Id) {
            return {
                ...user,
                communityId: updateData?.community?.CommunityId, 
                address: updateData?.community?.Address, 
                residentContactKey: updateData?.community?.ResidentContactKey, 
                phone: updateData?.community?.Phone, 
                residentKey: updateData?.community?.ResidentKey ,
                communityName:community?.name 
            };
        }
        return user;})
        let  upd = {
          communityUsers:update,
          communtiyList:CommunityDetails?.data?.communtiyList,
          userDirectory:CommunityDetails?.data?.userDirectory
        }
       
        const data = {
          data : upd
        }
        dispatch(Users.GetCommunityByUserIdSuccess(data))

    }
  },[updateData])
  const getColumns = (permissionType) => {
   
    return [
      { title: "Board Permission", dataIndex: "featureName" },
      {
        title: "Status",
        dataIndex: (permissionType === 0 || permissionType === false) ? "hasCommitteePermission" : "hasUserPermission",
        key: "status",
        render: (status, record) => (
          <Switch
            checked={status}
            disabled={(permissionType === 1 || permissionType === true) ? false : true}
            onChange={(checked) => handleSwitchChange(record, checked)}
            checkedChildren="Active"
            unCheckedChildren="Inactive"
          />
        ),
      },
    ];
  };
  
  const directoryColumns = [
    { title: "First Name", dataIndex: "firstName" },
    { title: "Last Name", dataIndex: "lastName" },
    { title: "Login Email", dataIndex: "loginEmail" },
    { title: "Address", dataIndex: "mudAddress" },
    { title: "Home Phone", dataIndex: "homePhone" },
    { title: "City", dataIndex: "city" },
    { title: "State", dataIndex: "state" },
    { title: "Zip", dataIndex: "zip" },
  ];

  const fetchCommunityByUserId = (id, current, pageSize, filter) => {
    const req = {
      pageNumber: current,
      pageSize: pageSize,
      filter: filter,
      userId: id,
    };
    dispatch(Users.GetCommunityByUserIdRequest({ request: req }));
  };
  const fetchFetures = (id) => {
    const req = {
      userId: id,
    };
    dispatch(Users.GetBoardFeatureRequest({ request: req }));
  };
  const fetchCommunityFeaturesFetures = (id) => {
    const req = {
      filter: "",
    };
    dispatch(Users.GetAllFeatureRequest({ request: req }));
  };
  useEffect(() => {
    fetchCommunityByUserId(
      userid?.id,
      communityPagination.current,
      communityPagination.pageSize
    );

    fetchFetures(userid?.id);
    fetchCommunityFeaturesFetures();
    let request = {
      userId : userid?.id
    }
    dispatch(Users.GetUserByIdRequest(request))
  }, [userid?.id]);

  useEffect(() => {
    if (CommunityDetails?.data?.communityUsers?.length > 0) {
      setCommunityList(CommunityDetails?.data?.communityUsers);
     
      // if(CommunityDetails?.data?.communityUsers?.length > 0){
      //   const newFeatureListAll = CommunityDetails?.data?.communityUsers?.map(data=>{
      //     const d = data?.permissions?.map(datas=>{
      //       return{
      //         id:data?.communityId,
      //         name:datas
      //       }
      //     })
      //     return d;
      //   })
      //   setFeatureOptions(newFeatureListAll)
      // }
   
      setCommunityPagination({
        ...communityPagination,
        total: CommunityDetails?.data?.communityUserTotalRecords,
      });
    } else {
      setCommunityList([]);
    }

    if (CommunityDetails?.data?.userDirectory?.length > 0) {
      setUserDirectoryList(CommunityDetails?.data?.userDirectory);
      setDirectoryPagination({
        ...directoryPagination,
        total: CommunityDetails?.data?.userDirectoryTotalRecords,
      });
    } else {
      setUserDirectoryList([]);
    }
   
  }, [CommunityDetails]);
  
  useEffect(() => {
    if (BoardFeatures?.data?.length > 0) {
        const isActiveFeaturePresent = BoardFeatures?.data?.some(item => item.hasUserPermission === true);
      
      setFeatureList(BoardFeatures?.data);
      setType(isActiveFeaturePresent)
    } else {
      setFeatureList([]);
    }
  }, [BoardFeatures]);

  useEffect(() => {
    let newarr = [];
    if (Features?.data?.featureList?.length > 0) {
      for (let items of Features?.data?.featureList) {
        if (items?.isBoardVuePermission == false) {
          newarr.push(items);
        }
      }
      setAdditionalFeature(newarr);
    } else {
      setAdditionalFeature([]);
    }
  }, [Features]);

const HandleUpdate = ()=>{
    const data = {
        userId : userid?.id,
        featureData : JSON.stringify(featureList)
    }
    let req = {
request:data
    }
    debugger
    dispatch(Users.UpdateFeatureRequest(req))
}
const HandleCommitee = ()=>{
  const data = {
      userId : userid?.id,
      featureData : null
  }
  let req = {
request:data
  }
  dispatch(Users.UpdateFeatureRequest(req))
}
const HandleEdit = ()=>{
  setEdit(!edit)
}

const viewCommunityFormHandle = (record)=>{
  setCommunityData(record);
  dispatch(Community.UpdatedCommunityByIdSuccess(record))
setViewCommunityForm(true);
}
useEffect(()=>{
  if(getCommunityById.data ){
    dispatch(Community.UpdatedCommunityByIdSuccess(getCommunityById.data))
    setCommunityData(getCommunityById.data);
  }
    
  
},[getCommunityById])
const viewCommunityFormHandleCancel = ()=>{
  setViewCommunityForm(false);
  }
  
  const HandleOnChange = (e) => {
    const { value, id } = e?.target;
    const req = {
        communityId: value,
        userId : userid?.id
    };
  
    dispatch(Community.GetCommunityByIdRequest({ request: req }));
   
      
    
  };
  const HandleOnChanges = (e,communityId) => {
    const { value, id } = e?.target;
    const req = {
        communityId: communityId,
        userId : userid?.id,
        permissionId:value
    };
    const request = {
      request:req,
      handle:handleResPermission
    }
dispatch(Community.PermissionUpdateCommunityByIdRequest(request))
  
      
    
  };
  const handleResPermission = ()=>{
    fetchCommunityByUserId(
      userid?.id,
      communityPagination.current,
      communityPagination.pageSize
    );
  }
  const HandleCommunityForm = ()=>{
    return(<>
    <CommunityForm onCancel={viewCommunityFormHandleCancel} users={communityData}  HandleOnChange={HandleOnChange} community={getAllCommunitys?.data} isEdit={true} disabled={!viewCommunityForm}/>
    </>)
  }
  useEffect(()=>{
   dispatch(Community.GetAllCommunitysRequest())
  },[])
  useEffect(()=>{
   if(usersDetails?.data?.permissionId){
    userpermissionId["permissionId"] = usersDetails?.data?.isActive
    setUserPermissionId(userpermissionId)
   }
  },[usersDetails])
  const expandedRowRender = (record) => (
    <List
      bordered
      dataSource={record.permissions || []}
      renderItem={(item) => (
        <List.Item>
          {item}
        </List.Item>
      )}
      header={<b>Features</b>}
      locale={{ emptyText: <Empty  /> }}
      style={{ margin: '0 16px' }}
    />
  )
  const GenratePass = ()=>{
    let request = {
      userId: userid?.id
    }
    dispatch(Users.UserRegenratePasswordRequest({request:request}))
  }
  return (
    <>

    {loading && <Spinner />}
      <Row gutter={[12, 50]}>
        <Col sm={24}>
          <Card  title="User Information" extra={<>
            {
              password?.data &&  <Button><Typography.Text copyable>{password?.data}</Typography.Text></Button>
            }
           
            <Tooltip  placement="topLeft" title={"Regenrate password"} >
            <Button  className="edit-button" onClick={GenratePass} icon={<SyncOutlined  spin={genrateLoading}/> } >Regenrate Password </Button> 
            </Tooltip > <Switch
            className="edit-button"
            checked={userpermissionId?.permissionId}
            onChange={(checked) => handleUserStatusChange( checked)}
            checkedChildren="User Active"
            unCheckedChildren="User Inactive"
          />
            <Button className="edit-button" icon={edit ? <EditOutlined /> : '' } onClick={HandleEdit}>{!edit ? "Cancel" : ''}</Button> 
         </>}>
            <CreateUser users={usersDetails?.data} communityList={communityList} getAllCommunitys={communityList} isEdit={true} disabled={edit} />
          </Card>
        </Col>
        <Col sm={24}>
          <Card title="Community">
            {/* <DynamicTable
              data={communityList ?? []}
              columns={communityColumns}
              pagination={false}
              scroll={{ x: 600, y: 300 }}
            /> */}
            <DynamicTable
              columns={communityColumns}
              pagination={false}
              expandable={{
                expandedRowRender,
                defaultExpandedRowKeys: ["0"],
              }}
              rowKey="id"
              data={communityList ?? []}
              scroll={{ x: 600, y: 300 }}
            />
          </Card>
        </Col>
        
        {/* {featureList?.length > 0 &&  <Col sm={24}>
          <Card
            title="Board View (Board View features Permission )"
            extra={
     <RadioCircle options={Options} value={type ? 1 : 0} name="Permission" onChange={handleBoardTypeChange}/>
            }
          >
            <DynamicTable
              data={featureList ?? []}
            columns={getColumns(type)}
              pagination={false}
              scroll={{ x: 600, y: 300 }}
            />
            <div className="feature-button" >
            {type  ?  <Button  onClick={HandleUpdate}>Done</Button> : ''}
            </div>
          </Card>
        </Col>} */}
        <Col sm={24}>
          <Card title="User Directory">
            <DynamicTable
              data={userDirectoryList ?? []}
              columns={directoryColumns}
              pagination={false}
              scroll={{ x: 600, y: 300 }}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        title="Edit Community"
        centered="center"
        open={viewCommunityForm}
        onOk={viewCommunityFormHandle}
        onCancel={viewCommunityFormHandleCancel}
        Cancel={viewCommunityFormHandleCancel}
        width={800} 
        bodyStyle={{ maxHeight: "500px", overflowY: "auto" }}
        loading={loading}
        children={HandleCommunityForm}
      />
    </>
  );
};

export default ViewUsers;
