import React from 'react';
import { Table } from 'antd';

const calculateColumnWidth = (text, padding = 16) => {
  return text?.length * 8 + padding;
};

const generateColumnsWithWidth = (columns, data) => {
  return columns.map((column) => {
    const longestValue = data?.reduce((max, row) => {
      const value = row[column.dataIndex];
      return value && value?.length > max?.length ? value : max;
    }, column.title);

    const width = calculateColumnWidth(longestValue);
    return { ...column, width, className: 'custom-column-class' }; 
  });
};

const DynamicTable = ({ rowKey,onRow,expandable,scroll,columns, data,pagination,onChange }) => {
  const columnsWithWidth = generateColumnsWithWidth(columns, data);

  return (
    <Table 
      columns={columns}
      dataSource={data}
      pagination={pagination}
      rowKey={rowKey}
      expandable={expandable}
      rowClassName={'table-header'}
      // bordered
      onRow={onRow}
     
      scroll={{ x: 400, y: 'calc(100vh - 500px)'  }}
      onChange={onChange}
    />
  );
};

export default DynamicTable;
