import React, { useState } from 'react';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const SlidingTabsDemo = ({ tabs }) => {
  const [mode, setMode] = useState('top');

  return (
    <div>
      <Tabs  defaultActiveKey="1" tabPosition={mode} style={{ height: 220 }}>
        {tabs.map((tab, index) => (
          <TabPane tab={tab.title} key={index}>
            {tab.content}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default SlidingTabsDemo;
