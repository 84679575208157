import React from 'react';
import { Spin, Alert } from 'antd';


const Spinner = () => {
  return(
  //   <div className='overlay'>
  //    <div className="spinner-overlay">
  //   <div className="spinner-container">
  //     <Spin wrapperClassName="sffsdfs" size="large">
  //       <Alert message="Please wait..." type="info" />
  //     </Spin>
  //   </div>
  // </div>
  //   </div>
  // <div className='overlay'>
  // <div class="loader"></div></div>
  
  <div class="drawing" id="loading">
		<div class="loading-dot"></div>
	</div>
  )
}

export default Spinner;
