const dev = "https://aamallaccesshubapi.azurewebsites.net/";
const prod = "https://aamallaccesshubapi.azurewebsites.net/";

const test = "https://aamallaccesshubapi.azurewebsites.net/";


const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development": {
      console.log(process.env.NODE_ENV, "process.env.NODE_ENV");
      console.log("delopment");
      return dev;
    }

    case "production": {
      console.log(process.env.NODE_ENV, "process.env.NODE_ENV");
      console.log("production");
      return prod;
    }
    case "test": {
      console.log(process.env.NODE_ENV, "process.env.NODE_ENV");
      console.log("test");
      return test;
    }
    default:
      break;
  }
};

export const env = getEnv();
