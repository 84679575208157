import Constant from '../../Constants/Constants';
  class Users{
    static GetAllUsersRequest = (credentials) => ({
        type: Constant.GET_ALL_USERS_REQUEST,
        payload: credentials,
      });
      
    
      static GetAllUsersSuccess = (userData) => ({
        type: Constant.GET_ALL_USERS_SUCCESS,
        payload: userData,
      });
      
      static GetAllUsersFailure = (error) => ({
        type: Constant.GET_ALL_USERS_FAILURE,
        payload: error,
      });
      
      static GetCommunityByUserIdRequest = (credentials) => ({
        type: Constant.GET_COMMUNITY_BY_USERID_REQUEST,
        payload: credentials,
      });
      
    
      static GetCommunityByUserIdSuccess = (userData) => ({
        type: Constant.GET_COMMUNITY_BY_USERID_SUCCESS,
        payload: userData,
      });
      
      static filterCommunityCommunityByUserIdSuccess = (userData) => ({
        type: Constant.FILTER_COMMUNITY_BY_USERID_SUCCESS,
        payload: userData,
      });

      static GetCommunityByUserIdFailure = (error) => ({
        type: Constant.GET_COMMUNITY_BY_USERID_FAILURE,
        payload: error,
      });
      static GetBoardFeatureRequest = (credentials) => ({
        type: Constant.GET_BOARD_FEATURES_REQUEST,
        payload: credentials,
      });
      
    
      static GetBoardFeatureSuccess = (userData) => ({
        type: Constant.GET_BOARD_FEATURES_SUCCESS,
        payload: userData,
      });
      
      static GetBoardFeatureFailure = (error) => ({
        type: Constant.GET_BOARD_FEATURES_FAILURE,
        payload: error,
      });

      static GetAllFeatureRequest = (credentials) => ({
        type: Constant.GET_ALL_FEATURES_REQUEST,
        payload: credentials,
      });
      
    
      static GetAllFeatureSuccess = (userData) => ({
        type: Constant.GET_ALL_FEATURES_SUCCESS,
        payload: userData,
      });
      
      static GetAllFeatureFailure = (error) => ({
        type: Constant.GET_ALL_FEATURES_FAILURE,
        payload: error,
      });

      static UpdateFeatureRequest = (credentials) => ({
        type: Constant.UPDATE_FEATURES_REQUEST,
        payload: credentials,
      });
      
    
      static UpdateFeatureSuccess = (userData) => ({
        type: Constant.UPDATE_FEATURES_SUCCESS,
        payload: userData,
      });
      
      static UpdateFeatureFailure = (error) => ({
        type: Constant.UPDATE_FEATURES_FAILURE,
        payload: error,
      });

      static GetUsersRequest = (credentials) => ({
        type: Constant.GET_USERS_REQUEST,
        payload: credentials,
      });
      
    
      static GetUsersSuccess = (userData) => ({
        type: Constant.GET_USERS_SUCCESS,
        payload: userData,
      });
      
      static GetUsersFailure = (error) => ({
        type: Constant.GET_USERS_FAILURE,
        payload: error,
      });

      static GetFeaturesByCommunityIdRequest = (credentials) => ({
        type: Constant.GET_FEATURES_BY_COMMUNITYID_REQUEST,
        payload: credentials,
      });
      
    
      static GetFeaturesByCommunityIdSuccess = (userData) => ({
        type: Constant.GET_FEATURES_BY_COMMUNITYID_SUCCESS,
        payload: userData,
      });
      
      static GetFeaturesByCommunityIdFailure = (error) => ({
        type: Constant.GET_FEATURES_BY_COMMUNITYID_FAILURE,
        payload: error,
      });

      static SetFeaturesByCommunityIdRequest = (credentials) => ({
        type: Constant.SET_FEATURES_BY_COMMUNITYID_REQUEST,
        payload: credentials,
      });
      
    
      static SetFeaturesByCommunityIdSuccess = (userData) => ({
        type: Constant.SET_FEATURES_BY_COMMUNITYID_SUCCESS,
        payload: userData,
      });
      
      static SetFeaturesByCommunityIdFailure = (error) => ({
        type: Constant.SET_FEATURES_BY_COMMUNITYID_FAILURE,
        payload: error,
      });

      static GetUserByIdRequest = (credentials) => ({
        type: Constant.GET_USERS_BYID_REQUEST,
        payload: credentials,
      });
      
    
      static GetUserByIdSuccess = (userData) => ({
        type: Constant.GET_USERS_BYID_SUCCESS,
        payload: userData,
      });
      
      static GetUserByIdFailure = (error) => ({
        type: Constant.GET_USERS_BYID_FAILURE,
        payload: error,
      });

      static CreateUserRequest = (credentials) => ({
        type: Constant.CREATE_USER_REQUEST,
        payload: credentials,
      });
      
    
      static CreateUserSuccess = (userData) => ({
        type: Constant.CREATE_USER_SUCCESS,
        payload: userData,
      });
      static CreateUserReset = (userData) => ({
        type: Constant.RESET_USER_SUCCESS,
        payload: userData,
      });
      static CreateUserFailure = (error) => ({
        type: Constant.CREATE_USER_FAILURE,
        payload: error,
      });

      static UpdateUserRequest = (credentials) => ({
        type: Constant.UPDATE_USER_REQUEST,
        payload: credentials,
      });
      
    
      static UpdateUserSuccess = (userData) => ({
        type: Constant.UPDATE_USER_SUCCESS,
        payload: userData,
      });
      
      static UpdateUserFailure = (error) => ({
        type: Constant.UPDATE_USER_FAILURE,
        payload: error,
      });

      static UserStatusRequest = (credentials) => ({
        type: Constant.USER_STATUS_REQUEST,
        payload: credentials,
      });
      
    
      static UserStatusSuccess = (userData) => ({
        type: Constant.USER_STATUS_SUCCESS,
        payload: userData,
      });
      
      static UserStatusFailure = (error) => ({
        type: Constant.USER_STATUS_FAILURE,
        payload: error,
      });

      static UserRegenratePasswordRequest = (credentials) => ({
        type: Constant.USER_REGENRATE_PASSWORD_REQUEST,
        payload: credentials,
      });
      
    
      static UserRegenratePasswordSuccess = (userData) => ({
        type: Constant.USER_REGENRATE_PASSWORD_SUCCESS,
        payload: userData,
      });
      
      static UserRegenratePasswordFailure = (error) => ({
        type: Constant.USER_REGENRATE_PASSWORD_FAILURE,
        payload: error,
      });
  }
  export default Users;