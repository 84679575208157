class Constant {
  static LOGIN_REQUEST = "LOGIN_REQUEST";
  static LOGIN_SUCCESS = "LOGIN_SUCCESS";
  static LOGIN_FAILURE = "LOGIN_FAILURE";

  static LOGOUT = "LOGOUT";
  static IS_SIDEBAR = "IS_SIDEBAR";
  static AUTH_TOKEN = "auth_token";

  static GET_DETAILS_REQUEST = "GET_DETAILS_REQUEST";
  static GET_DETAILS_SUCCESS = "GET_DETAILS_SUCCESS";
  static GET_DETAILS_FAILURE = "GET_DETAILS_FAILURE";

  static GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
  static GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
  static GET_ALL_USERS_FAILURE = "GET_ALL_USERS_FAILURE";

  static GET_COMMUNITY_BY_USERID_REQUEST = "GET_COMMUNITY_BY_USERID_REQUEST";
  static GET_COMMUNITY_BY_USERID_SUCCESS = "GET_COMMUNITY_BY_USERID_SUCCESS";
  static GET_COMMUNITY_BY_USERID_FAILURE = "GET_COMMUNITY_BY_USERID_FAILURE";
  static FILTER_COMMUNITY_BY_USERID_SUCCESS = "FILTER_COMMUNITY_BY_USERID_SUCCESS";

  static GET_ALL_FEATURES_REQUEST = "GET_ALL_FEATURES_REQUEST";
  static GET_ALL_FEATURES_SUCCESS = "GET_ALL_FEATURES_SUCCESS";
  static GET_ALL_FEATURES_FAILURE = "GET_ALL_FEATURES_FAILURE";

  static GET_BOARD_FEATURES_REQUEST = "GET_BOARD_FEATURES_REQUEST";
  static GET_BOARD_FEATURES_SUCCESS = "GET_BOARD_FEATURES_SUCCESS";
  static GET_BOARD_FEATURES_FAILURE = "GET_BOARD_FEATURES_FAILURE";

  static UPDATE_FEATURES_REQUEST = "UPDATE_FEATURES_REQUEST";
  static UPDATE_FEATURES_SUCCESS = "UPDATE_FEATURES_SUCCESS";
  static UPDATE_FEATURES_FAILURE = "UPDATE_FEATURES_FAILURE";

  static GET_USERS_REQUEST = "GET_USERS_REQUEST";
  static GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
  static GET_USERS_FAILURE = "GET_USERS_FAILURE";

  static GET_FEATURES_BY_COMMUNITYID_REQUEST = "GET_FEATURES_BY_COMMUNITYID_REQUEST";
  static GET_FEATURES_BY_COMMUNITYID_SUCCESS = "GET_FEATURES_BY_COMMUNITYID_SUCCESS";
  static GET_FEATURES_BY_COMMUNITYID_FAILURE = "GET_FEATURES_BY_COMMUNITYID_FAILURE";

  static SET_FEATURES_BY_COMMUNITYID_REQUEST = "SET_FEATURES_BY_COMMUNITYID_REQUEST";
  static SET_FEATURES_BY_COMMUNITYID_SUCCESS = "SET_FEATURES_BY_COMMUNITYID_SUCCESS";
  static SET_FEATURES_BY_COMMUNITYID_FAILURE = "SET_FEATURES_BY_COMMUNITYID_FAILURE";

  static GET_USERS_BYID_REQUEST = "GET_USERS_BYID_REQUEST";
  static GET_USERS_BYID_SUCCESS = "GET_USERS_BYID_SUCCESS";
  static GET_USERS_BYID_FAILURE = "GET_USERS_BYID_FAILURE";

  static SET_USER_ROLE = "SET_USER_ROLE";

  static GET_ALL_COMMUNITYS_REQUEST = "GET_ALL_COMMUNITYS_REQUEST";
  static GET_ALL_COMMUNITYS_SUCCESS = "GET_ALL_COMMUNITYS_SUCCESS";
  static GET_ALL_COMMUNITYS_FAILURE = "GET_ALL_COMMUNITYS_FAILURE";

  static GET_COMMUNITY_BYID_REQUEST = "GET_COMMUNITY_BYID_REQUEST";
  static GET_COMMUNITY_BYID_SUCCESS = "GET_COMMUNITY_BYID_SUCCESS";
  static GET_COMMUNITY_BYID_FAILURE = "GET_COMMUNITY_BYID_FAILURE";

  static UPDATED_COMMUNITY_BYID_REQUEST = "UPDATED_COMMUNITY_BYID_REQUEST";
  static UPDATED_COMMUNITY_BYID_SUCCESS = "UPDATED_COMMUNITY_BYID_SUCCESS";
  static UPDATED_COMMUNITY_BYID_FAILURE = "UPDATED_COMMUNITY_BYID_FAILURE";

  static STATUS_UPDATE_COMMUNITY_BYID_REQUEST = "STATUS_UPDATE_COMMUNITY_BYID_REQUEST";
  static STATUS_UPDATE_COMMUNITY_BYID_SUCCESS = "STATUS_UPDATE_COMMUNITY_BYID_SUCCESS";
  static STATUS_UPDATE_COMMUNITY_BYID_FAILURE = "STATUS_UPDATE_COMMUNITY_BYID_FAILURE";

  static PERMISSION_UPDATE_COMMUNITY_BYID_REQUEST = "PERMISSION_UPDATE_COMMUNITY_BYID_REQUEST";
  static PERMISSION_UPDATE_COMMUNITY_BYID_SUCCESS = "PERMISSION_UPDATE_COMMUNITY_BYID_SUCCESS";
  static PERMISSION_UPDATE_COMMUNITY_BYID_FAILURE = "PERMISSION_UPDATE_COMMUNITY_BYID_FAILURE";
  
  static CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
  static CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
  static CREATE_USER_FAILURE = "CREATE_USER_FAILURE";
  static RESET_USER_SUCCESS = "RESET_USER_SUCCESS";

  static UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
  static UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
  static UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

  static USER_STATUS_REQUEST = "USER_STATUS_REQUEST";
  static USER_STATUS_SUCCESS = "USER_STATUS_SUCCESS";
  static USER_STATUS_FAILURE = "USER_STATUS_FAILURE";

  static USER_REGENRATE_PASSWORD_REQUEST = "USER_REGENRATE_PASSWORD_REQUEST";
  static USER_REGENRATE_PASSWORD_SUCCESS = "USER_REGENRATE_PASSWORD_SUCCESS";
  static USER_REGENRATE_PASSWORD_FAILURE = "USER_REGENRATE_PASSWORD_FAILURE";
}

export default Constant;
