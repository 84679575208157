import React from 'react';
import { InputNumber, Form } from 'antd';

const InputNumberComponent = (props) => {
    const { onChange, name, rules, size, className, status, placeholder, label, maxLength, step, precision } = props;
const handle = (e)=>{
  let req = {
    target:{
      id:name,
      value:e
    }
  }
  onChange(req)
}
    return (
        <>
            <Form.Item
                label={label}
                name={name}
                rules={rules}
            >
                <InputNumber
                    placeholder={placeholder}
                    maxLength={maxLength}
                    step={step}
                    status={status}
                    className={className}
                    size={size}
                    onChange={handle}
                    precision={precision}
                    parser={value => value.replace(/[^\d.]/g, '')}  // Removes non-numeric characters
                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}  // Adds comma formatting for thousands
                />
            </Form.Item>
        </>
    );
};

export default InputNumberComponent;
