import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const ProfileInfo = ({GetUser})=>{
    

    return(
        <>
        <div className='content-wrapper'>
        <h1 className='secondary-heading pb-3'>Request Information</h1>
        <Row justify={"space-around"}  gutter={[16,16]}>
            {Object.keys(GetUser)?.length > 0 && Object.keys(GetUser).map((key) => (
        <Col className='col-lg-4 col-md-6 col-sm-12' key={key} >
          <strong className='information-label'>{key}:</strong> <span className='information-text'>{GetUser[key]}</span>
        </Col>
      ))}
           
        </Row>
        </div>
        </>
    )
}
export default ProfileInfo;