import React from 'react';
import { Modal } from 'antd';
const ModalComponent = (props) => {
    const {title ,bodyStyle,centered, open, Ok,isShow, Cancel, width, children,loading,pageName} = props;
  return (
    <>

      <Modal
        title={title}
        centered={centered}
        open={open}
        loading={loading}
        onOk={() => Ok(false)}
        onCancel={() => Cancel()}
        width={width}
        className='common-button custom-modal-height'
        footer={isShow ? true : false}
        bodyStyle={bodyStyle}
      >
        {children(pageName)}
      </Modal>
    </>
  );
};
export default ModalComponent;